/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql, withPrefix} from "gatsby"

import Header from "./header"
import "./layout.css"
import {Helmet} from "react-helmet";

const FooterCopyrightStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "0",
    padding: "10px",
    fontSize: "px",
    fontFamily: "Gotham Bold",
    textTransform: "uppercase",
    textAlign: "center",
    background: "#d3d3d3",

}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
            <p className="copyright" style={FooterCopyrightStyles}>
          Powered By <a href="https://greenflamingobcn.com">Green Flamingo</a>
                <span style={{display: 'inline-block', marginLeft: "5px"}}> © {new Date().getFullYear()}</span>
            </p>
        </footer>
      </div>
        <Helmet>
            <script src={withPrefix('../../jquery-2.1.0.min.js')} type="text/javascript" />
            <script src={withPrefix('../../pdf.combined.min.js')} type="text/javascript" />
            <script src={withPrefix('../../wow_book.min.js')} type="text/javascript" />
        </Helmet>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
