import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import logo from "../images/logo.png"

const Logo = styled.div`
  max-width:150px;
  display:flex;
  justify-content: center;
  align-items: center;
  img {
    width:100%;
    margin:0;
  }
`

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#051032`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        display:`flex`,
        justifyContent: `flex-start`,
        alignItems: `center`
      }}
    >
        <Logo>
            <a href="https://crnjanski.edu.rs"><img src={logo} alt=""/></a>
        </Logo>
      {/*<h1 style={{ margin: 0 }}>*/}
      {/*  /!*<Link*!/*/}
      {/*  /!*  to="/"*!/*/}
      {/*  /!*  style={{*!/*/}
      {/*  /!*    color: `white`,*!/*/}
      {/*  /!*    textDecoration: `none`,*!/*/}
      {/*  /!*  }}*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  {siteTitle}*!/*/}
      {/*  /!*</Link>*!/*/}
      {/*</h1>*/}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
